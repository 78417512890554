import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="userselection"
export default class extends Controller {
  static targets = ["irrigation_field_id"]
  irrigation(event) {
    let user = event.target.selectedOptions[0].value
    let target = window.irrigation_field_id.id
    get(`/admin/irrigations/user_selection?target=${target}&selected=${user}`,{
      responseKind: "turbo-stream"
    });
  
  }
}

// Entry point for the build script in your package.json
import "./controllers"

import "./src/jquery.js"
import "./src/jquery-ui.js"

// $ = window.$ = window.jQuery = require("jquery");
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

import {Turbo} from "@hotwired/turbo-rails"
window.Turbo = Turbo

import {TimePicker} from "timepicker/jquery.timepicker.js"
window.Timepicker = TimePicker

import { get } from '@rails/request.js'
window.get = get
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="irrigation"
export default class extends Controller {
  static targets = ["irrigation_finish"]
  static values = {
    url: String,
    param: String
  }
  connect() {
    if (this.irrigation_finishTarget.id === "") {
      this.irrigation_finishTarget.id = Math.random().toString(36)
    }
  }
  change(event) {
    let params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append("target", this.irrigation_finishTarget.id)
    get(`${this.urlValue}?${params}`,{
      responseKind: "turbo-stream"
    });
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="timepicker"
export default class extends Controller {
  connect() {
    this.initializeTimepicker();
  }

  initializeTimepicker() {
    $(this.element).timepicker({
      // Configuration options for the timepicker can go here
    });
  }
}
